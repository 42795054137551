<template>
  
  <div class="col-md-7 col-sm-7 mt-10 boxmain" v-if="my_data==null">

    <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n titleh2n_news">
                  <Skeleton></Skeleton>
                </h2>

              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12col-md-12 block-1">
                <article class="art_text table-responsive">
                    <Skeleton v-for="(v,k) in 10" :key="k" height="100px"></Skeleton>
                </article>
              </div>
            </div>
          </div>
     </SkeletonTheme>
  </div>


   <div class="col-md-7 col-sm-7 mt-10 boxmain" v-else>
          <div class="container boxtintuc">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n titleh2n_news">
                    {{my_data.name}}
                </h2>

              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12col-md-12 block-1">
                <div class="art_text table-responsive">
                    <div  v-html="my_data.content">
                    </div>


                    <div class="container" style="height: 20px;">

                    </div>
                     <TopNews :Header="3"></TopNews>
                </div>

                  
              </div>
            </div>

          
          </div>
            

             

  </div>


</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";
import TopNews from "@/components/default/TopNews.vue";  
  import axios from 'axios';
export default {
  
 
  data() {
    return {
      

      myurl:window.my_url,
      my_data:null,
      meta:{
        title:"",
        description:""
      }
      
    };
  },

  components: {
    Skeleton,
    SkeletonTheme,
    TopNews
    
  },
  created(){
        
       let p1=  axios.get(window.my_api+"api/news/get-news-by-slug?slug="+this.$route.params.slug,
                 {
                                 headers: {
                                   language: this.$i18n.locale
                                 }
                  })
          .then( (res)=>{
             this.is_loading_slideshow=false;
            if(res.status==200){
                this.my_data=res.data.data;
                  
                  let FD=new FormData();

                  FD.append("id",this.my_data.id);

                  axios.post(window.my_api+"api/news/update-viewer",
                        FD
                    )
                    .then( ()=>{
                      

                    });

                 this.meta={
                          title:this.my_data.name,
                          description:this.my_data.description
                       }
            }
             

          });
          
        Promise.all([p1]).then(()=>{
          
            // let formData=new FormData();
            // let viewer= this.my_data.viewer==null ? 1 : this.my_data.viewer*1+1;
            // formData.set("viewer", viewer );
            // formData.set("id",this.my_data.id);
            //    axios
            //     .post(window.my_api + "api/news/create-or-update-news", formData)
            //     .then(() => {
                    
            //     });
        });
           
      
  },
  metaInfo(){
     return {
        title:this.meta.title,
        description:this.meta.description
     }
  }

}
</script>
